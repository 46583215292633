import React, { FunctionComponent } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../layout/layout"
import SEO from "../seo"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import customBlocks from "../customBlocks/customBlocks"
import style from "./article.module.css"

interface IArticleProps {
  data: GatsbyTypes.ArticleQuery;
}

const Article: FunctionComponent<IArticleProps> = ({data}) => {
  const article = data.article
  const title = (article.openGraph && article.openGraph.title) || article.title
  const description = (article.openGraph && article.openGraph.description) || article.lead
  const openGraphImage = (article.openGraph && article.openGraph.image && article.openGraph.image.asset && article.openGraph.image.asset.fixed.src) || (article.sharingImageAlt.asset && article.sharingImageAlt.asset.fixed.src)
  return(
    <Layout>
      <SEO title={title} image={openGraphImage && openGraphImage} description={description} slug={`nieuws/${article.slug.current}`} />
      <div className={style.main}>
        <div className={style.header}>
          <div className={style.headerContent}>
            <Link to="/nieuws/" className={style.subtitle}>Nieuws</Link>
            <h1 className={style.title}>{article.title}</h1>
            <div className={style.lead}>{article.lead}</div>
          </div>
          <Img fluid={{ ...article.mainImage.asset.fluid, aspectRatio: 16/9}} className={style.image} />
        </div>
        <div className={style.content}>
          <BlockContent blocks={article._rawBody} serializers={customBlocks} />
        </div>
      </div>
    </Layout>
  );
}

export default Article

export const articleQuery = graphql`
  query Article($id: String) {
    article: sanityArticle(id: {eq: $id}) {
      slug {
        current
      }
      _id
      title
      lead
      publishedAt(formatString: "D MMMM YYYY", locale: "nl")
      mainImage {
        asset {
          fluid {
            aspectRatio
            sizes
            src
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
      sharingImageAlt: mainImage {
        asset {
          fixed(width: 1200, height: 630) {
            src
          }
        }
      }
      openGraph {
        title
        description
        image {
          asset {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
      _rawBody(resolveReferences: {maxDepth: 5})
    }
  }
`